<template>
  <section class="all-documents-view">
    <h1 style="font-weight: 600">My Documents</h1>
    <div class="vue-data-table-default">
      <div>
        <data-tables-server
          :data="allDocuments"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationPropsForTable"
          :page-sizes="paginationProps"
          :total="total"
          style="width: 100%"
          v-loading="loading"
          v-if="!getIsMobile"
          @query-change="loadData"
          @sort-change="sortChange"
          @change="loadData"
        >
          <el-table-column
            label="Name"
            width="290"
            class="d-flex flex-column justify-content-between"
          >
            <template slot-scope="scope">
              <div class="pdf-file">
                <img
                  src="@/assets/img/icons/pdf.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </div>
              <div
                style="margin-left: 30px"
                class="content"
                @click.prevent="goToDocuments(scope.row._id)"
              >
                {{ scope.row.name | truncate(22, "...") }}
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Actions" width="120">
            <template slot-scope="scope">
              <el-tooltip content="View Document">
                <el-button
                  plain
                  type="primary"
                  size="mini"
                  @click="goToDocuments(scope.row._id)"
                >
                  <i class="el-icon-view"></i>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column label="Document Status" width="170">
            <template slot-scope="scope">
              <div>
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'SENT'
                  "
                >
                  <div
                    class="circle"
                    style="background-color: dodgerblue"
                  ></div>
                  Sent
                </span>
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'COMPLETED'
                  "
                >
                  <div class="circle" style="background-color: green"></div>
                  Completed
                </span>
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'ARCHIVED'
                  "
                >
                  <div class="circle" style="background-color: yellow"></div>
                  Archived
                </span>
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'VOIDED'
                  "
                >
                  <div class="circle" style="background-color: pink"></div>
                  Voided
                </span>
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'APPROVED'
                  "
                >
                  <div class="circle" style="background-color: peru"></div>
                  Approved
                </span>
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'DECLINED'
                  "
                >
                  <div class="circle" style="background-color: #635e5e"></div>
                  Declined
                </span>
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'EXPIRED'
                  "
                >
                  <div class="circle" style="background-color: red"></div>
                  Expired
                </span>
                <span
                  v-if="
                    scope &&
                    scope.row &&
                    scope.row.document_status &&
                    scope.row.document_status == 'DRAFT'
                  "
                >
                  <div class="circle" style="background-color: orange"></div>
                  Draft
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Created at">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">
                {{ scope.row.created_at | globalDateTimeFormat }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Updated at">           
             <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">
                {{ scope.row.updated_at | globalDateTimeFormat }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Expires at">
            <template slot-scope="scope">
              <i class="el-icon-stopwatch"></i>
              <span style="margin-left: 10px">
                {{ scope.row.expires_at | globalDateTimeFormat }}
              </span>
            </template>
          </el-table-column>
        </data-tables-server>
      </div>
    </div>
    <div class="vue-data-table-mobile-view">
      <data-tables-server
        :data="allDocuments"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :pagination-props="paginationPropsForTable"
        :total="total"
        style="width: 100%"
        v-loading="loading"
        v-if="getIsMobile"
        @query-change="loadData"
        @sort-change="sortChange"
        @change="loadData"
        class="document-list-mobile"
      >
        <el-table-column
          label="Name"
          width="290"
          class="d-flex flex-column justify-content-between"
        >
          <template slot-scope="scope">
            <div class="pdf-file">
              <img
                src="@/assets/img/icons/pdf.svg"
                alt="icon"
                class="img-fluid"
              />
            </div>
            <div
              style="margin-left: 30px"
              class="content"
              @click.prevent="goToDocuments(scope.row._id)"
            >
              {{ scope.row.name | truncate(22, "...") }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Actions" width="120">
          <template slot-scope="scope">
            <el-tooltip content="View Document">
              <el-button
                plain
                type="primary"
                size="mini"
                @click="goToDocuments(scope.row._id)"
              >
                <i class="el-icon-view"></i>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="Document Status">
          <template slot-scope="scope">
            <div>
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'SENT'
                "
              >
                <div class="circle" style="background-color: dodgerblue"></div>
                Sent
              </span>
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'COMPLETED'
                "
              >
                <div class="circle" style="background-color: green"></div>
                Completed
              </span>
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'ARCHIVED'
                "
              >
                <div class="circle" style="background-color: yellow"></div>
                Archived
              </span>
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'VOIDED'
                "
              >
                <div class="circle" style="background-color: pink"></div>
                Voided
              </span>
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'APPROVED'
                "
              >
                <div class="circle" style="background-color: peru"></div>
                Approved
              </span>
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'DECLINED'
                "
              >
                <div class="circle" style="background-color: orange"></div>
                Declined
              </span>
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'EXPIRED'
                "
              >
                <div class="circle" style="background-color: red"></div>
                Expired
              </span>
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'DRAFT'
                "
              >
                <div class="circle" style="background-color: orange"></div>
                Draft
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="expand-column-item">
              <div class="expand-column-item-label">Created at:</div>
              <div class="expand-column-item-content">
                <template v-if="scope.row.created_at">
                  <i class="el-icon-time"></i>
                  <span style="margin-left: 10px">
                    {{ scope.row.created_at | globalDateTimeFormat }}
                  </span>
                </template>
              </div>
            </div>
            <div class="expand-column-item">
              <div class="expand-column-item-label">Updated at:</div>
              <div class="expand-column-item-content">
                <template v-if="scope.row.updated_at">
                  <i class="el-icon-time"></i>
                  <span style="margin-left: 10px">
                    {{ scope.row.updated_at | globalDateTimeFormat }}
                  </span>
                </template>
              </div>
            </div>
            <div class="expand-column-item">
              <div class="expand-column-item-label">Expires at:</div>
              <div class="expand-column-item-content">
                <template v-if="scope.row.expires_at">
                  <i class="el-icon-stopwatch"></i>
                  <span style="margin-left: 10px">
                    {{ scope.row.expires_at | globalDateTimeFormat }}
                  </span>
                </template>
              </div>
            </div>
          </template>
        </el-table-column>
      </data-tables-server>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      allDocuments: [],
      pageSize: 10,
      total: 0,
      currentPage: 1,
      page: 1,
      popUpText: "View Document",
      loading: false,
      order_type: null,
      order_by: null,
    };
  },
  computed: {
    ...mapGetters("applicationUsers", ["getUserDocuments"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    paginationProps() {
      return [5, 10, 20, 50];
    },
    paginationPropsForTable() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
  },
  async mounted() {
    await this.fetchDocuments();
  },
  methods: {
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchDocuments();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchDocuments();
      }
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchDocuments();
      }
    },
    async fetchDocuments() {
      this.loading = true;
      this.allDocuments = [];
      let params = {
        limit: parseInt(this.pageSize),
        page: parseInt(this.page)
      };
      if (this.order_by) {
        params.order_by = this.order_by;
      }
      if (this.order_type) {
        params.order_type = this.order_type;
      }
      await this.$store.dispatch("applicationUsers/fetchUserDocuments", params);
      if (this.getUserDocuments?.data) {
        this.allDocuments = this.getUserDocuments.data;
      }
      this.total = this.getUserDocuments.total;
      this.loading = false;
    },
    goToDocuments(id) {
      this.$router.push({
        name: "employee-documents-custom-document-pp",
        params: {
          employee_document_id: id,
        },
      });
    },
  },
};
</script>
<style scoped>
.pdf-file {
  align-content: center;
  align-items: center;
  height: 5px;
  width: 30px;
}
.img-fluid {
  margin: default;
  height: 15px;
  width: 30px;
  margin-top: 8px;
}
.circle {
  margin-right: 6px;
  margin-top: 6px;
  height: 8px;
  width: 8px;
  display: inline-block;
  border-radius: 50%;
}
.content {
  margin: default;
}
</style>
